/**
 * Rounds a Date to a threshold in minutes
 * @param  date [new Date()]
 * @param  threshold [15] Rounding threshold in minutes
 * @param  roundingMethod Use floor or ceil to always round up or down
 */
export function roundDate({
  date = new Date(),
  threshold = 15,
  roundingMethod,
}: {
  date: Date,
  threshold: number,
  roundingMethod?: 'floor' | 'ceil'
}): Date {
  const ms = 1000 * 60 * threshold;
  let roundedDate;

  if (roundingMethod) {
    roundedDate = Math[roundingMethod](date.getTime() / ms) * ms;
  } else {
    roundedDate = Math.round(date.getTime() / ms) * ms;
  }
  return new Date(roundedDate);
}
